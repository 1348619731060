import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'
import { STATUSES } from '@constants/slices'
import { getDashboardSilent } from '@pages/Dashboard/slice'

export const shopSlice = createSlice({
    name: 'shop',
    initialState: {
        value: {},
        status: STATUSES.INIT,
    },
    reducers: {
        loaded: (_, { payload }) => ({ value: payload, status: STATUSES.LOADED }),
        loadedFreeCourseUpdate: (state, { payload }) => ({
            ...state,
            value: payload,
            status: STATUSES.LOADED,
        }),
        loading: state => ({...state, value: {}, status: STATUSES.LOADING }),
        error: state => ({...state, status: STATUSES.ERROR }),
    },
})

export const buyCourseWithCoins = id => dispatch => {
    dispatch(loading())
    api({
            endpoint: '/postTransaction',
            areaPath: '/plakos',
            method: 'POST',
            body: {
                courseId: id,
            },
        })
        .then(res => {
            if (res.status === 200) dispatch(loaded({ id: id, userCoins: res.data.userCoins }))
            else dispatch(error())
        })
        .catch(() => dispatch(error()))
}

export const updateFreeCourseEnrollment = (id, isEnrolled) => dispatch => {
    dispatch(loading())
    api({
        endpoint: '/user/postFreeCourseUpdate',
        method: 'POST',
        body: {
            action: isEnrolled ? 'REMOVE' : 'ADD',
            courseId: id,
        },
    }).then(res => {
        if (res.status === 200) {
            dispatch(loadedFreeCourseUpdate({ id: id }))
            dispatch(getDashboardSilent())
        } else dispatch(error())
    })
}

export const selectShop = state => state.shop

export const { loaded, loading, error, loadedFreeCourseUpdate } = shopSlice.actions
export default shopSlice.reducer