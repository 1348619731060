import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import placosLogo from '@assets/logo.svg'

import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SearchIcon from '@mui/icons-material/Search'
import SchoolIcon from '@mui/icons-material/School'
import ShopIcon from '@mui/icons-material/LocalMallRounded'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import HeaderIconEntry from '@components/atoms/HeaderIconEntry'
import SearchInput from './Search/SearchInput'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '@pages/Login/slice'
import { FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  Header_AppBar: {
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    bottom: 'auto',
    background: `${theme.palette.header.background} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px ${theme.palette.shadow.default}`,
    '-webkit-box-shadow': `0px 3px 6px ${theme.palette.shadow.default}`,
  },
  Header_AppBar__Logged: {
    background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
  },
  Header_MainContainer: {
    width: '100%',
    margin: '0 16px 0 16px',
    height: '64px',
  },
  Header_RightContainer: {
    textAlign: 'right',
  },
  Header_Toolbar: {
    margin: '0 32px 0 32px',
    padding: '0',
    borderBottom: 'none',
    [theme.breakpoints.down(768)]: {
      margin: '0 16px 0 16px',
    },
  },
  Header_Toolbar__Logged: {
    borderBottom: `1px solid ${theme.palette.primary.mainTransparent}`,
  },
  Header_SearchContainer: {
    marginTop: '8px',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Header_IconButton: {
    padding: '17px',
    color: theme.palette.secondary.gray,
    [theme.breakpoints.down(430)]: {
      padding: '17px 12px',
    },
  },
  Header_IconButton__Search: {
    display: 'none',
    [theme.breakpoints.down(960)]: {
      display: 'inline-flex',
    },
  },
  Header_IconButton__Active: {
    color: theme.palette.primary.mainTransparent,
  },
  Header_Icon: {
    fontSize: '32px',
    [theme.breakpoints.down(430)]: {
      fontSize: '28px',
    },
  },
  Header_LeftContainer: {
    textAlign: 'left',
    height: '50px',
    display: 'flex',
    margin: 'auto',
    position: 'relative',
  },
  Header_Title: {
    fontSize: '24px',
    margin: 'auto 24px auto 0',
    color: theme.palette.secondary.gray,
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Header_Title__Active: {
    color: theme.palette.primary.main,
  },

  Header_Logo: {
    margin: '8px 24px 0 0',
    cursor: 'pointer',
    width: 'auto',
    height: '32px',
    [theme.breakpoints.down('360')]: {
      margin: '0px 16px 0 0',
      height: '24px',
    },
  },
  Header_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Header_Title_Container: {
    maxWidth: '250px',
  },
  Header_TextTooltip: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  Header_App_Title: {
    fontSize: '32px',
    lineHeight: '50px',
    color: theme.palette.primary.main,
    fontWeight: '500',
    padding: 0,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(600)]: {
      fontSize: '24px',
    },
  },
}))

const Header = () => {
  const history = useHistory()
  const pathname = useLocation().pathname
  const dispatch = useDispatch()
  const intl = useIntl()
  const c = useStyles()

  const [isSearchShow, setIsSearchShow] = useState(true)
  const [isButtonShow, setIsButtonShow] = useState(true)
  const [path, setPath] = useState('/')
  const { location } = history

  useEffect(() => {
    handleHeaderElements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, location])

  const handleHeaderElements = () => {
    setIsSearchShow(false)
    setIsButtonShow(true)
    switch (history?.location?.pathname) {
      case '/login':
        setIsButtonShow(false)
        break
      case '/impressum':
        setIsButtonShow(false)
        break
      default:
        setIsSearchShow(true)
    }
  }

  const handleRedirect = path => {
    setPath(path)
    history.push(path)
  }

  return (
    <AppBar
      position='static'
      className={clsx(c.Header_AppBar, pathname !== '/login' && c.Header_AppBar__Logged)}
    >
      <Toolbar
        className={clsx(
          c.Header_Toolbar,
          pathname !== '/login' && c.Header_Toolbar__Logged
        )}
      >
        <Box className={c.Header_MainContainer}>
          <Grid
            container
            style={{ height: '100%', width: '100%' }}
            wrap='nowrap'
            direction='row'
            justifyContent='space-between'
          >
            <Grid
              item
              container
              md={pathname !== '/login' ? 5 : 12}
              sm={pathname !== '/login' ? 5 : 12}
              xs={pathname !== '/login' ? 3 : 12}
              alignItems={'center'}
            >
              <Tooltip
                title={intl.formatMessage({ id: 'shared.toDashboard' })}
                classes={{ tooltip: c.Header_TextTooltip }}
              >
                <img
                  src={placosLogo}
                  alt='Placos logo'
                  className={c.Header_Logo}
                  onClick={() => {
                    handleRedirect('/')
                  }}
                />
              </Tooltip>
              {pathname === '/login' && (
                <Typography className={c.Header_App_Title}>
                  <FormattedMessage id='shared.appTitle' />
                </Typography>
              )}
            </Grid>
            <Grid item>
              {isSearchShow && (
                <Box className={c.Header_SearchContainer}>
                  <SearchInput />
                </Box>
              )}
            </Grid>
            {isButtonShow ? (
              <Grid item md={5} sm={5}>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'flex-end'}
                  alignItems={'flex-start'}
                  style={{ flexWrap: 'nowrap' }}
                >
                  <Tooltip
                    title={intl.formatMessage({ id: 'shared.search' })}
                    classes={{ tooltip: c.Header_TextTooltip }}
                  >
                    <Grid
                      container
                      style={{ width: 'auto' }}
                      direction='row'
                      alignItems='center'
                    >
                      <IconButton
                        className={clsx(
                          c.Header_IconButton,
                          c.Header_IconButton__Search,
                          pathname === '/search' && c.Header_IconButton__Active
                        )}
                        onClick={() => handleRedirect('/search')}
                        size='large'
                      >
                        <SearchIcon className={c.Header_Icon}></SearchIcon>
                      </IconButton>
                    </Grid>
                  </Tooltip>

                  <HeaderIconEntry
                    callback={() => handleRedirect('/')}
                    activ={pathname === '/'}
                    tooltip={intl.formatMessage({ id: 'shared.toDashboard' })}
                  >
                    <SchoolIcon className={c.Header_Icon}></SchoolIcon>
                  </HeaderIconEntry>
                  <HeaderIconEntry
                    callback={() => handleRedirect('/profile')}
                    activ={pathname === '/profile'}
                    tooltip={intl.formatMessage({ id: 'shared.myProfile' })}
                  >
                    <AccountCircleIcon className={c.Header_Icon}></AccountCircleIcon>
                  </HeaderIconEntry>
                  <HeaderIconEntry
                    callback={() => dispatch(logout())}
                    tooltip={intl.formatMessage({ id: 'login.logout' })}
                  >
                    <ExitToAppIcon className={c.Header_Icon} />
                  </HeaderIconEntry>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
