import React from 'react'
import { makeStyles } from '@mui/styles'

import { useSelector, useDispatch } from 'react-redux'

import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { selectProfile, postProfile } from '@pages/Profile/slice'

import FormLine from './Form/FormLine'
import { parse, isMatch } from 'date-fns'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { STATUSES } from '@constants/slices'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from '@mui/material'
import AssesmentCountDown from '@components/molecules/AssesmentCountDown'

const useStyles = makeStyles(theme => {
  return {
    Settings: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    Settings_Title: {
      fontSize: '34px',
      lineHeight: '45px',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down(700)]: {
        fontSize: '20px',
        lineHeight: '28px',
        marginBottom: theme.spacing(1),
      },
    },
    Settings_Button: {
      margin: theme.spacing(2),
      width: 220,
      [theme.breakpoints.down(700)]: {
        width: '150px',
        fontSize: '12px',
      },
    },
    Settings_resetPWLink: {
      cursor: 'pointer',
      fontSize: '16px',
      lineHeight: '21px',
      color: theme.palette.primary.main,
      marginBottom: '6px',
      fontWeight: 500,
      [theme.breakpoints.down(500)]: {
        fontSize: '12px',
      },
    },
  }
})

const validationSchema = yup.object({
  firstName: yup.string().required('FirstName is required'),
  lastName: yup.string().required('LastName is required'),
  userName: yup.string().required('UserName is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  assessmentDate: yup.date().nullable().typeError('Enter a valid date'),
  userPhone: yup.string(),
})

const Settings = () => {
  const c = useStyles()
  const dispatch = useDispatch()
  const { value: profile, status } = useSelector(selectProfile)
  const isLoading = status === STATUSES.LOADING
  const intl = useIntl()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      userName: profile?.userName || '',
      email: profile?.email || '',
      assessmentDate:
        profile?.assessmentDate && isMatch(profile.assessmentDate, 'dd.MM.yyyy')
          ? parse(profile.assessmentDate, 'dd.MM.yyyy', new Date())
          : profile.assessmentDate || null,
      userPhone: profile?.userPhone || '',
    },
    validationSchema,
    onSubmit: values => {
      dispatch(postProfile(values))
    },
  })

  const {
    handleChange,
    handleSubmit,
    values: { firstName, lastName, userName, email, assessmentDate, userPhone },
    touched,
    errors,
  } = formik

  return (
    <Paper className={c.Settings} square elevation={2}>
      <Typography className={c.Settings_Title} component='h3'>
        <FormattedMessage id='profile.information' />
      </Typography>
      <Box>
        <Grid container direction='column'>
          <form onSubmit={handleSubmit}>
            <FormLine
              title={intl.formatMessage({ id: 'profile.firstName' })}
              placeholder='Max'
              id='firstName'
              name='firstName'
              value={firstName}
              onChange={handleChange}
              disabled={isLoading}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              variant='standard'
            />
            <FormLine
              title={intl.formatMessage({ id: 'profile.secondName' })}
              placeholder='Mustermann'
              id='lastName'
              name='lastName'
              value={lastName}
              onChange={handleChange}
              disabled={isLoading}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              variant='standard'
            />
            <FormLine
              title={intl.formatMessage({ id: 'profile.nickname' })}
              placeholder='Alex95'
              id='userName'
              name='userName'
              value={userName}
              onChange={handleChange}
              disabled={isLoading}
              error={touched.userName && Boolean(errors.userName)}
              helperText={touched.userName && errors.userName}
              variant='standard'
            />
            <FormLine
              title={intl.formatMessage({ id: 'profile.email' })}
              placeholder='user@gmail.com'
              id='email'
              name='email'
              value={email}
              onChange={handleChange}
              disabled={isLoading}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              variant='standard'
            />
            <FormLine
              title={intl.formatMessage({ id: 'profile.data' })}
              data
              id='assessmentDate'
              name='assessmentDate'
              value={
                isMatch(assessmentDate, 'dd.MM.yyyy')
                  ? parse(assessmentDate, 'dd.MM.yyyy', new Date())
                  : assessmentDate
              }
              onChange={value => {
                formik.setFieldValue('assessmentDate', value)
              }}
              disabled={isLoading}
              error={touched.assessmentDate && Boolean(errors.assessmentDate)}
              helperText={touched.assessmentDate && errors.assessmentDate}
              variant='standard'
            />
            <FormLine
              title={intl.formatMessage({ id: 'profile.phone' })}
              placeholder='01xx xxxxxxx'
              id='userPhone'
              name='userPhone'
              value={userPhone}
              onChange={handleChange}
              disabled={isLoading}
              error={touched.userPhone && Boolean(errors.userPhone)}
              helperText={touched.userPhone && errors.userPhone}
              variant='standard'
            />
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item>
                <Button
                  className={c.Settings_Button}
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={isLoading}
                >
                  <FormattedMessage id='profile.save' />
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid item style={{ margin: '8px auto' }}>
            <Link
              className={c.Settings_resetPWLink}
              target='_blank'
              rel='noopener'
              href='https://kurse.plakos-akademie.de/lost-password/'
              underline='hover'
            >
              <FormattedMessage id='profile.changePassword' />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default Settings
