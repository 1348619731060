import React, { useState, useEffect, useRef, useMemo } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDashboard,
  selectDashboard,
  selectDashboardStatus,
} from '@pages/Dashboard/slice'

import InputAdornment from '@mui/material/InputAdornment'
import InputBase from '@mui/material/InputBase'
import SearchResults from '@components/molecules/Search/SearchResults'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Backdrop, Typography } from '@mui/material'

import { FormattedMessage, useIntl } from 'react-intl'
import { STATUSES } from '@constants/slices'
import FilterButton from './FilterButton'
import ListDivider from '@components/atoms/ListDivider'
import { current } from '@reduxjs/toolkit'

const useStyles = makeStyles(theme => ({
  Search: {
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down(960)]: {
      width: '100%',
      zIndex: 0,
    },
  },
  Search_InputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  Search_Input: {
    backgroundColor: theme.palette.primary.white,
    margin: '8px 0 auto 0px',
    borderRadius: '6px',
    height: '39px',
    width: '221px',
    color: theme.palette.primary.main,
    paddingLeft: '14px',
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down(960)]: {
      margin: '0 0 0 10px',
    },
    [theme.breakpoints.down(500)]: {
      width: '100%',
      margin: '0 16px 0 10px',
    },
  },
  Search_Icon: {
    color: theme.palette.secondary.gray,
  },
  Search_Results: {
    left: 'calc(50% - (66vw/2))',
    width: '66vw',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.primary.white,
    maxHeight: 'min(80vh)',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: `0px ${theme.spacing(2)}`,
    [theme.breakpoints.down(960)]: {
      width: 'calc(100%)',
      position: 'relative',
      top: 0,
      left: 0,
      margin: '0px',
      maxHeight: 'none',
      borderRadius: '24px 24px 0px 0px',
      padding: '0px 16px',
    },
  },
  Search_Backdrop: {
    zIndex: 1,
  },
  Search_Results_ExpandContainer: {
    position: 'absolute',
    width: '66vw',
    left: 'calc(50% - (66vw/2))',
    backgroundColor: 'white',
    [theme.breakpoints.down(960)]: {
      position: 'static',
      width: '100%',
    },
  },
  Search_Results_Filter_ButtonWrapper: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: `0px ${theme.spacing(2)}`,
    padding: `${theme.spacing(1)} 0px`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 16,
  },
}))

const collectSearchItems = coursesRaw => {
  const courses = []
  const lessons = []

  if (coursesRaw) {
    courses.push(
      ...coursesRaw.reduce((accCourses, item) => {
        accCourses.push({
          id: item?.id,
          title: item?.title,
          isCourse: true,
          iconSrc: item?.icon,
          courseName: item?.title,
          courseIsFree: item?.courseIsFree,
          userIsEnrolled: item?.userIsEnrolled,
          pricing: item?.pricing,
        })
        if (item.lessons && item['lessons'].length) {
          lessons.push(
            ...item.lessons.reduce((accLessons, lesson) => {
              accLessons.push({
                id: lesson?.id,
                title: lesson?.title,
                isCourse: false,
                idCourse: item?.id,
                iconSrc: '',
                courseName: item?.title,
                courseIsFree: item?.courseIsFree,
                userIsEnrolled: item?.userIsEnrolled,
              })
              return accLessons
            }, [])
          )
        }
        return accCourses
      }, [])
    )
  }
  return [...courses, ...lessons]
}

const FILTER = {
  FREE: 'FREE',
  PAYED: 'PAYED',
  OWNED: 'OWNED',
}

const SearchInput = ({ isMobile }) => {
  const c = useStyles()
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()
  const timer = useRef(null)
  const dashboard = useSelector(selectDashboard)
  const dashboardStatus = useSelector(selectDashboardStatus)
  const data = useMemo(() => collectSearchItems(dashboard.courses || []), [dashboard])

  const [isBackdrop, setIsBackdrop] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredSearchItems, setFilteredSearchItems] = useState([])

  useEffect(() => {
    const { pathname } = history?.location
    if (
      dashboardStatus !== STATUSES.LOADED &&
      pathname !== '/dashboard' &&
      pathname !== '/' &&
      !isMobile &&
      pathname !== '/login'
    ) {
      dispatch(getDashboard())
    }
  }, [dispatch, history, isMobile])

  useEffect(() => {
    filterSearchItems(searchValue)
  }, [])

  const filterSearchItems = filter => {
    const lowercasedFilter = filter?.toLowerCase()

    const preFilteredData = data.filter(item => {
      if (item?.title?.toLowerCase()?.includes(lowercasedFilter)) {
        return true
      } else return false
    })

    setFilteredSearchItems(preFilteredData)
  }

  const handleSearchOnChange = event => {
    const { value } = event.target

    setSearchValue(value)
    clearTimeout(timer.current)
    timer.current = null

    if (value.length <= 2) {
      setFilteredSearchItems([])
    }

    timer.current = setTimeout(() => {
      timer.current = null

      if (value.length > 2) {
        filterSearchItems(value)
      }

      if (value.length > 2 && !isMobile) {
        setIsBackdrop(true)
      } else {
        setIsBackdrop(false)
      }
    }, 400)
  }

  const handleClickAway = () => {
    if (!isMobile) {
      setSearchValue('')
      setIsBackdrop(false)
      clearTimeout(timer.current)
    }
  }

  const getSpecialMessage = type => {
    if (!dashboard.courses) {
      return <CircularProgress />
    }

    if (searchValue.length === 0) {
      return intl.formatMessage({ id: 'shared.startTypingForResults' })
    }

    if (searchValue.length < 3) {
      return intl.formatMessage({ id: 'shared.continueTypingForResults' })
    }

    if (searchValue.length >= 3 && !timer.current) {
      if (
        (type === 'lesson' &&
          filteredSearchItems.filter(item => !item.isCourse).length === 0) ||
        (type === 'course' &&
          filteredSearchItems.filter(item => item.isCourse).length === 0)
      )
        return intl.formatMessage({ id: 'shared.nothingFound' })
    }

    if (timer.current) {
      return <CircularProgress />
    }

    return null
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={c.Search}>
          <div className={c.Search_InputWrapper}>
            {isMobile && (
              <IconButton onClick={history.goBack} size='large'>
                <ArrowBack className={c.Search_Icon} />
              </IconButton>
            )}
            <InputBase
              className={c.Search_Input}
              value={searchValue}
              placeholder={intl.formatMessage({ id: 'shared.search' })}
              onChange={handleSearchOnChange}
              disabled={!dashboard.courses}
              startAdornment={
                <InputAdornment position='start' color='inherit'>
                  <SearchIcon className={c.Search_Icon} />
                </InputAdornment>
              }
            />
          </div>
          {((searchValue.length > 2 && !!data.length) || isMobile) && (
            <>
              <div className={c.Search_Results_ExpandContainer}>
                <div className={c.Search_Results}>
                  {!dashboard.courses ? (
                    <CircularProgress style={{ margin: '16px auto' }} />
                  ) : searchValue.length < 3 ? (
                    <Typography style={{ margin: '16px auto' }}>
                      <FormattedMessage
                        id={
                          searchValue.length === 0
                            ? 'shared.startTypingForResults'
                            : 'shared.continueTypingForResults'
                        }
                      ></FormattedMessage>
                    </Typography>
                  ) : timer.current ? (
                    <CircularProgress style={{ margin: '16px auto' }} />
                  ) : (
                    <>
                      <SearchResults
                        onBackdropSet={setIsBackdrop}
                        onSearchValueSet={setSearchValue}
                        filteredSearchItems={filteredSearchItems.filter(
                          item => item.isCourse
                        )}
                        specialMessage={getSpecialMessage('course')}
                        isMobile={isMobile}
                      />
                      <ListDivider
                        text={intl.formatMessage({ id: 'shared.lessons' })}
                      ></ListDivider>
                      <SearchResults
                        onBackdropSet={setIsBackdrop}
                        onSearchValueSet={setSearchValue}
                        filteredSearchItems={filteredSearchItems.filter(
                          item => !item.isCourse
                        )}
                        specialMessage={getSpecialMessage('lesson')}
                        isMobile={isMobile}
                      ></SearchResults>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>
      <Backdrop className={c.Search_Backdrop} open={isBackdrop} />
    </>
  )
}

export default SearchInput
