import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Typography } from '@mui/material'
const useStyles = makeStyles(theme => ({
  PriceInCoins_coinPrice: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  PriceInCoins_coinImage: {
    width: 32,
    height: 32,
  },
  PriceInCoins_PriceInEuro: {
    color: theme.palette.secondary.gray,
  },
}))

const PriceInCoins = ({ price, color }) => {
  const c = useStyles()

  return (
    <Grid container justifyContent='center' alignItems='center' wrap='nowrap'>
      <Typography className={c.PriceInCoins_coinPrice} style={{ color: color }}>
        {price}
      </Typography>
    </Grid>
  )
}

export default PriceInCoins
