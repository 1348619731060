import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, withStyles } from '@mui/styles'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

import BackgroundImage from '@assets/login_background.jpg'

import Spacer from '@components/atoms/Spacer'
import { login, selectLoginErrorStatus, selectLoginStatus } from './slice'

import { useHistory, useLocation } from 'react-router'
import StoreBadge from 'react-store-badge'

import { clearCurrentUser } from '@services/currentUser'
import { STATUSES } from '@constants/slices'
import { get } from 'lodash'
import {
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material'
import theme from 'app/theme'
import { useFormik } from 'formik'
import * as yup from 'yup'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'
import RegisterPopUp from '@components/atoms/RegisterPopUp'
import { PAGE_TITLE_TYPES, setPageTitle } from '@services/gaIntegration'

const useStyles = makeStyles(theme => ({
  Login: {},
  Login_MainContainer: {
    height: '100vh',
  },
  Login_Form_Wrapper: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '15vw',
    height: '100%',
    [theme.breakpoints.down(1000)]: {
      justifyContent: 'center',
      paddingRight: '0',
    },
  },
  Login_Paper: {
    borderRadius: '15px',
  },
  Login_Button: {
    height: '48px',
    width: '212px',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(500)]: {
      height: '30px',
      width: '125px',
      marginTop: theme.spacing(1),
      lineHeight: '22px',
    },
  },
  Login_BackgroundImage: {
    position: 'fixed',
    minHeight: '100vh',
    minWidth: '100%',
    zIndex: -1,
    opacity: '0.65',
    filter: 'blur(6px)',
    left: '-520px',
    top: '-4px',
    [theme.breakpoints.down(1200)]: {
      left: '-800px',
    },
    [theme.breakpoints.down(600)]: {
      left: '-800px',
    },
  },
  Login_FormContainer: {
    width: '432px',
    height: '612px',
    [theme.breakpoints.down(1280)]: {
      height: '524px',
      width: '370px',
    },
    [theme.breakpoints.down(500)]: {
      height: '445px',
      width: '270px',
    },
  },
  Login_FormInputWrapper: {
    [theme.breakpoints.down(500)]: {
      width: '100%',
      padding: '0 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  Login_FormInput: {
    width: '273px',
    height: '48px',
    borderColor: theme.palette.primary.main,
    marginBottom: '32px',
    [theme.breakpoints.down(500)]: {
      height: '32px',
      width: '160px',
      marginBottom: '16px',
      '& input': {
        boxSizing: 'border-box',
        height: '32px',
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
  },
  Login_LinkContainer: {
    width: '273px',
    marginTop: '32px',
    [theme.breakpoints.down(500)]: {
      width: '255px',
      paddingRight: theme.spacing(1),
    },
  },
  Login_Link: {
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '21px',
    color: theme.palette.primary.main,
    marginBottom: '6px',
    fontWeight: 500,
    [theme.breakpoints.down(500)]: {
      fontSize: '12px',
    },
  },
  Login_TextLabel: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(500)]: {
      fontSize: '14px',
      display: 'block',
      width: '100%',
    },
  },
  Login_Store_Badges: {
    [theme.breakpoints.down(500)]: {
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& img': {
        width: '125px',
      },
    },
  },
  Login_RevealPassword: {
    margin: '0px',
    padding: '0px',
    color: theme.palette.primary.main,
  },
  Login_ErrorText: {
    fontSize: 16,
    color: theme.palette.error.main,
    width: '80%',
    textAlign: 'center',
    margin: 'auto',
  },
}))

const CustomTextField = withStyles({
  root: {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  notchedOutline: {
    borderColor: theme.palette.primary.main,
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
})(OutlinedInput)

const Login = () => {
  const c = useStyles({})
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const fromPath = get(location, ['state', 'from', 'pathname'], '/')
  const status = useSelector(selectLoginStatus)
  const errorStatus = useSelector(selectLoginErrorStatus)
  const intl = useIntl()
  const [revealPassword, setRevealPassword] = useState(false)
  const [serverError, setSeverError] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    setPageTitle(PAGE_TITLE_TYPES.LOGIN)
  }, [])

  const validationSchema = yup.object({
    username: yup
      .string()
      .required(intl.formatMessage({ id: 'login.enterUsernameOrEmail' })),
    password: yup.string().required(intl.formatMessage({ id: 'login.enterPassword' })),
  })

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: () => {
      dispatch(login(username, password))
        .then(() => {
          history.push(fromPath)
          history.go(0)
        })
        .catch(() => setSeverError(true))
    },
  })

  const {
    handleChange,
    values: { username, password },
    touched,
    errors,
  } = formik

  useEffect(() => {
    clearCurrentUser()
  }, [])

  const errorMessage = useMemo(() => {
    return (
      (errorStatus === 401 && intl.formatMessage({ id: 'login.wrongCredentials' })) ||
      (errorStatus >= 500 && intl.formatMessage({ id: 'login.serverUnreachable' }))
    )
  }, [errorStatus, intl])

  const customHandleChange = e => {
    handleChange(e)
    setSeverError(false)
  }

  return (
    <>
      <img
        className={c.Login_BackgroundImage}
        src={BackgroundImage}
        alt='leftcard background image'
      ></img>
      <Grid
        className={clsx(c.Login_MainContainer, popupOpen ? c.Login_PreventScroll : null)}
        container
        justifyContent='flex-start'
        alignItems='center'
      >
        <Grid container item md={12} className={c.Login_Form_Wrapper}>
          <Grid item className={c.Login}>
            <Paper className={c.Login_Paper}>
              <form className={c.Login_Form} onSubmit={formik.handleSubmit}>
                <Grid
                  className={c.Login_FormContainer}
                  container
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Grid item className={c.Login_FormInputWrapper}>
                    <Typography className={c.Login_TextLabel} color='primary'>
                      <FormattedMessage id='login.mailOrUsername' />
                    </Typography>
                    <CustomTextField
                      className={c.Login_FormInput}
                      name='username'
                      variant='outlined'
                      value={username}
                      onChange={customHandleChange}
                      disabled={status === STATUSES.LOADING}
                      error={touched.username && Boolean(errors.username)}
                      helperText={touched.username && errors.username}
                    />
                    <Spacer size='1rem' />
                  </Grid>
                  <Grid item className={c.Login_FormInputWrapper}>
                    <Typography className={c.Login_TextLabel} color='primary'>
                      <FormattedMessage id='login.password' />
                    </Typography>
                    <CustomTextField
                      className={c.Login_FormInput}
                      variant='outlined'
                      name='password'
                      type={!revealPassword ? 'password' : 'text'}
                      value={password}
                      onChange={customHandleChange}
                      disabled={status === STATUSES.LOADING}
                      error={
                        (touched.password && Boolean(errors.password)) || !!errorStatus
                      }
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            className={c.Login_RevealPassword}
                            aria-label='toggle password visibility'
                            onClick={() => setRevealPassword(!revealPassword)}
                            size='large'
                          >
                            {!revealPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  {errors.username ? (
                    <Grid item>
                      <div className={c.Login_ErrorText}>{errors.username}</div>
                    </Grid>
                  ) : errors.password ? (
                    <Grid item>
                      <div className={c.Login_ErrorText}>{errors.password}</div>
                    </Grid>
                  ) : serverError ? (
                    <Grid item>
                      <div className={c.Login_ErrorText}>{errorMessage}</div>
                    </Grid>
                  ) : null}

                  <Grid item>
                    <Button
                      disabled={status === STATUSES.LOADING}
                      className={c.Login_Button}
                      type='submit'
                      size='large'
                      variant='contained'
                      color='primary'
                      startIcon={<HomeOutlinedIcon />}
                    >
                      <FormattedMessage id='login.login' />
                    </Button>
                  </Grid>
                  <Grid
                    className={c.Login_LinkContainer}
                    container
                    item
                    direction='column'
                    alignItems='flex-end'
                  >
                    <Grid item>
                      <Link
                        className={c.Login_Link}
                        target='_blank'
                        rel='noopener'
                        href='https://kurse.plakos-akademie.de/lost-password/'
                        underline='hover'
                      >
                        <FormattedMessage id='login.lostPassword' />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        className={c.Login_Link}
                        onClick={() => setPopupOpen(true)}
                        underline='hover'
                      >
                        <FormattedMessage id='login.needAccount' />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        className={c.Login_Link}
                        target='_blank'
                        rel='noopener'
                        href='https://plakos-akademie.de/impressum/'
                        underline='hover'
                      >
                        <FormattedMessage id='login.impressum' />
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Spacer size='2rem' mobileSize='1rem' />
                    <Grid item className={c.Login_Store_Badges}>
                      <StoreBadge
                        name='Plakos Apps'
                        googlePlayUrl='https://play.google.com/store/apps/dev?id=7915128495049631097'
                        appStoreUrl='https://apps.apple.com/de/developer/plakos-gmbh/id1083933127'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <RegisterPopUp
          open={popupOpen}
          handleClose={() => setPopupOpen(false)}
        ></RegisterPopUp>
      </Grid>
    </>
  )
}

export default Login
