import {
  AppBar,
  Backdrop,
  Box,
  ClickAwayListener,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import MenuIcon from '@mui/icons-material/MenuRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SchoolIcon from '@mui/icons-material/School'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ShopIcon from '@mui/icons-material/LocalMallRounded'

import placosLogo from '@assets/logo.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '@pages/Login/slice'
import MobileHeaderEntry from '@components/atoms/MobileHeaderEntry'

const useStyles = makeStyles(theme => ({
  Header_AppBar: {
    position: 'relative',
    zIndex: 2,
    minHeight: 64,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    top: 0,
    bottom: 'auto',
    background: `${theme.palette.header.background} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px ${theme.palette.shadow.default}`,
    '-webkit-box-shadow': `0px 3px 6px ${theme.palette.shadow.default}`,
  },
  Header_AppBar__Open: {
    position: 'absolute',
  },
  Header_AppBar__Logged: {
    background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
  },
  Header_MainContainer: {
    width: '1852px',
    margin: '0 16px 0 16px',
    height: '64px',
  },
  Header_Toolbar: {
    margin: '0 32px 0 32px',
    padding: '0',
    borderBottom: 'none',
    [theme.breakpoints.down(768)]: {
      margin: '0 16px 0 16px',
    },
  },
  Header_Toolbar__Logged: {
    borderBottom: `1px solid ${theme.palette.primary.mainTransparent}`,
  },
  Header_IconButton: {
    padding: '17px',
    color: theme.palette.secondary.gray,
    [theme.breakpoints.down(430)]: {
      padding: '17px 12px',
    },
  },
  Header_IconButton__Search: {
    display: 'none',
    [theme.breakpoints.down(960)]: {
      display: 'inline-flex',
    },
  },
  Header_IconButton__Active: {
    color: theme.palette.primary.mainTransparent,
  },
  Header_Icon: {
    fontSize: '32px',
    [theme.breakpoints.down(430)]: {
      fontSize: '28px',
    },
  },
  Header_Logo: {
    margin: '8px 24px 0 0',
    cursor: 'pointer',
    width: '50px',
    height: '50px',
    [theme.breakpoints.down('360')]: {
      margin: '0px 16px 0 0',
      width: '36px',
      height: '36px',
    },
  },
  Header_TextTooltip: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  Header_App_Title: {
    fontSize: '32px',
    lineHeight: '50px',
    color: theme.palette.primary.main,
    fontWeight: '500',
    padding: 0,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(600)]: {
      fontSize: '24px',
    },
  },
  Header_ExpandContainer: {
    position: 'absolute',
    top: '64px',
    width: '100%',
    transition: 'height 0.5s ease-out',
    backgroundColor: theme.palette.background.colored,
    zIndex: 11,
    borderRadius: '0px 0px 16px 16px',
  },
  Header_ExpandContainer_hidden: {
    height: 0,
  },
  Header_ExpandContainer_shown: {
    height: '256px',
    paddingTop: theme.spacing(2),
  },
  Header_AppBar_BottomLine: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    transition: 'all 0.5s ease-out',
  },
  Header_AppBar_BottomLine_closed: {
    height: 1,
    width: '90%',
    left: '5%',
    right: '5%',
    top: '64px',
  },
  Header_AppBar_BottomLine_opened: {
    height: 3,
    width: '30%',
    left: '35%',
    right: '35%',
    borderRadius: '16px',
    top: '306px',
    zIndex: 11,
    [theme.breakpoints.down(430)]: {
      top: '304px',
    },
  },
}))

const headerEntries = [
  {
    title: 'shared.dashboard',
    icon: SchoolIcon,
    path: '/',
  },
  {
    title: 'shared.myProfile',
    icon: AccountCircleIcon,
    path: '/profile',
  },
  {
    title: 'shared.search',
    icon: SearchIcon,
    path: '/search',
  },
]

const MobileHeader = () => {
  const history = useHistory()
  const pathname = useLocation().pathname
  const dispatch = useDispatch()
  const intl = useIntl()
  const c = useStyles()
  const [path, setPath] = useState('/')

  const [isButtonShow, setIsButtonShow] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  //Needed for Animation synchronization
  const [isClosed, setIsClosed] = useState(true)

  useEffect(() => {
    let timeout
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      timeout = setTimeout(() => setIsClosed(true), 2000)
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isOpen])

  useEffect(() => {
    handleHeaderElements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, location])

  const handleHeaderElements = () => {
    setIsButtonShow(true)
    switch (history?.location?.pathname) {
      case '/login':
        setIsButtonShow(false)
        break
      case '/impressum':
        setIsButtonShow(false)
        break
      default:
    }
  }

  const handleRedirect = path => {
    setPath(path)
    setIsOpen(false)
    history.push(path)
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div
          className={clsx(
            c.Header_AppBar,
            pathname !== '/login' && c.Header_AppBar__Logged,
            !isClosed ? c.Header_AppBar__Open : null
          )}
        >
          <Toolbar className={clsx(c.Header_Toolbar)}>
            <Box className={c.Header_MainContainer}>
              <Grid
                container
                style={{ height: '100%', width: '100%' }}
                wrap='nowrap'
                direction='row'
                justifyContent='space-between'
              >
                <Grid
                  item
                  container
                  md={pathname !== '/login' ? 5 : 12}
                  sm={pathname !== '/login' ? 5 : 12}
                  xs={pathname !== '/login' ? 3 : 12}
                  alignItems={'center'}
                >
                  <Tooltip
                    title={intl.formatMessage({ id: 'shared.toDashboard' })}
                    classes={{ tooltip: c.Header_TextTooltip }}
                  >
                    <img
                      src={placosLogo}
                      alt='Placos logo'
                      className={c.Header_Logo}
                      onClick={() => {
                        handleRedirect('/')
                      }}
                    />
                  </Tooltip>
                  {pathname === '/login' && (
                    <Typography className={c.Header_App_Title}>
                      <FormattedMessage id='shared.appTitle' />
                    </Typography>
                  )}
                </Grid>
                {isButtonShow ? (
                  <Grid item md={5} sm={5}>
                    <Grid
                      container
                      direction={'row'}
                      justifyContent={'flex-end'}
                      alignItems={'flex-start'}
                      style={{ flexWrap: 'nowrap' }}
                    >
                      <Tooltip
                        title={intl.formatMessage({ id: 'shared.search' })}
                        classes={{ tooltip: c.Header_TextTooltip }}
                      >
                        <Grid
                          container
                          style={{ width: 'auto' }}
                          direction='row'
                          alignItems='center'
                        >
                          {!isOpen ? (
                            <IconButton
                              className={clsx(
                                c.Header_IconButton,
                                c.Header_IconButton__Search,
                                pathname === '/search' && c.Header_IconButton__Active
                              )}
                              onClick={() => handleRedirect('/search')}
                              size='large'
                            >
                              <SearchIcon className={c.Header_Icon}></SearchIcon>
                            </IconButton>
                          ) : (
                            <IconButton
                              className={clsx(c.Header_IconButton)}
                              onClick={() => dispatch(logout())}
                              size='large'
                            >
                              <ExitToAppIcon className={c.Header_Icon}></ExitToAppIcon>
                            </IconButton>
                          )}
                          <IconButton
                            className={clsx(c.Header_IconButton)}
                            onClick={() => setIsOpen(!isOpen)}
                            size='large'
                          >
                            {isOpen ? (
                              <CloseIcon className={c.Header_Icon}></CloseIcon>
                            ) : (
                              <MenuIcon className={c.Header_Icon}></MenuIcon>
                            )}
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
          </Toolbar>
          <div
            className={clsx(
              c.Header_ExpandContainer,
              isOpen ? c.Header_ExpandContainer_shown : c.Header_ExpandContainer_hidden
            )}
          >
            {isOpen &&
              headerEntries.map((elem, index) => (
                <MobileHeaderEntry
                  key={index}
                  Icon={elem.icon}
                  title={elem.title}
                  path={elem.path}
                  handleNavigation={handleRedirect}
                  index={index}
                ></MobileHeaderEntry>
              ))}
          </div>
          {pathname !== '/login' && (
            <div
              className={clsx(
                c.Header_AppBar_BottomLine,
                !isOpen
                  ? c.Header_AppBar_BottomLine_closed
                  : c.Header_AppBar_BottomLine_opened
              )}
            ></div>
          )}
        </div>
      </ClickAwayListener>
      <Backdrop style={{ zIndex: 1 }} open={isOpen}></Backdrop>
    </>
  )
}

export default MobileHeader
