import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  MessageFromHero_MainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  MessageFromHero_HeroImage: {
    width: '200px',
    height: 'auto',
  },
  MessageFromHero_Message: {
    maxWidth: '80%',
    textAlign: 'center',
  },
}))

const MessageFromHero = ({ message }) => {
  const c = useStyles()
  return (
    <Grid container className={c.MessageFromHero_MainContainer}>
      <Typography className={c.MessageFromHero_Message}>{message}</Typography>
    </Grid>
  )
}

export default MessageFromHero
