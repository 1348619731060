import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: {
      main: '#ff4757',
      mainTransparent: '#ff4757',
      white: '#FFFFFF',
      green: '#ff4757',
      variant1: '#ff4757',
      variant2: '#ff4757',
      variant3: '#ff4757',
      variant4: '#ff4757',
    },
    secondary: {
      main: '#ffb5bc',
      gray: '#707070',
      lightGray: '#C0C0C0',
      variant1: '#ffb5bc',
      variant2: '#ffb5bc',
      variant3: '#ffb5bc',
    },
    premium: {
      main: '#ff4757',
    },
    practiceMode: {
      primary: '#F9DFC0',
    },
    content: {
      accent: '#ff4757',
    },
    chat: {
      background: '#ff4757',
      foreground: '#333333',
    },
    background: {
      default: '#FFFFFF',
      light: '#fefefe',
      colored: '#f3f3f5',
      cookie: '#54595f',
    },
    disabled: {
      main: '#707070',
    },
    error: {
      main: '#ff4757',
    },
    text: {
      primary: 'black',
    },
    shadow: {
      default: '#00000029',
    },
    header: {
      background: 'rgba(255, 255, 255, 0.65)',
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: '#707070',
      },
    },
  },
})
