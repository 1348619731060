import React, { useContext, useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import AppContext from './context/index'

import Community from '@pages/Сommunity'
import Dashboard, { BETA_ENABELED } from '@pages/Dashboard'
import MobileSearch from '@pages/MobileSearch'
import Course from '@pages/Course'
import Lesson from '@pages/Lesson'
import Test from '@pages/Test'
import Topic from '@pages/Topic'
import Impressum from '@pages/Impressum'
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles'

import Header from '@components/molecules/Header'

import CssBaseline from '@mui/material/CssBaseline'
import theme from './theme'
import themeB from './themeB'
import ProfileLayout from '@components/organisms/ProfileLayout'
import Settings from '@pages/Profile/Settings'
import Login from '@pages/Login'
import { getCurrentUser } from '@services/currentUser'
import Notepad from '../@pages/Notepad'
import ScrollToTop from '../@components/atoms/ScrollToTop'
import Footer from '@components/molecules/Footer'
import { useMediaQuery } from '@mui/material'
import MobileHeader from '@components/molecules/MobileHeader'
import { emitter } from '@marvelapp/react-ab-test'

const PrivateRoute = ({ children, ...props }) => {
  const currentUser = getCurrentUser()

  return (
    <Route
      {...props}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export const getTheme = () => {
  let variantName = emitter.getActiveVariant('ThemeTest') || ''
  console.log(variantName)
  return !variantName || variantName === 'Plakos'
    ? extendTheme(theme)
    : extendTheme(themeB)
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const App = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down(900))
  let query = useQuery()

  const { appState, setAppState } = useContext(AppContext)
  const [consentUpdated, setConsentUpdated] = useState(false)

  useEffect(() => {
    appState.isReady = true
    setAppState(appState)
  })

  return (
    <CssVarsProvider theme={getTheme()}>
      <CssBaseline />
      {isMobile ? <MobileHeader></MobileHeader> : <Header />}
      <main style={{ minHeight: '100%' }}>
        <ScrollToTop>
          <Switch>
            <Route exact path='/login'>
              <Login />
            </Route>
            <Route exact path='/impressum'>
              <Impressum />
            </Route>
            <PrivateRoute exact path='/'>
              <Dashboard />
            </PrivateRoute>
            <Redirect from='/dashboard' to='/' />
            <PrivateRoute exact path='/search'>
              <MobileSearch />
            </PrivateRoute>
            <PrivateRoute exact path='/course/:courseId'>
              <Course />
            </PrivateRoute>
            <PrivateRoute exact path='/lesson/:courseId/:lessonId'>
              <Lesson />
            </PrivateRoute>
            <PrivateRoute exact path='/test/:courseId/:testId'>
              <Test />
            </PrivateRoute>
            <PrivateRoute exact path='/topic/:courseId/:topicId'>
              <Topic />
            </PrivateRoute>
            <PrivateRoute exact path='/notepad/:courseId'>
              <Notepad />
            </PrivateRoute>
            <ProfileLayout>
              <Switch>
                <PrivateRoute exact path='/profile'>
                  <Settings />
                </PrivateRoute>
                <Redirect from='*' to='/dashboard' />
              </Switch>
            </ProfileLayout>
          </Switch>
        </ScrollToTop>
      </main>
      <Footer></Footer>
    </CssVarsProvider>
  )
}
export default App
